import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { EventsPhotosService } from '../events-photos.service';

@Component({
  selector: 'app-photos-galery',
  templateUrl: './photos-galery.component.html',
  styleUrls: ['./photos-galery.component.scss']
})
export class PhotosGaleryComponent implements OnInit {

  constructor(public modalRef: MDBModalRef, private eventsPhotosService: EventsPhotosService) { }

  imagesWithCaption = [];

  ngOnInit() {
    this.imagesWithCaption = this.eventsPhotosService.eventsPhotos;
  }
}
