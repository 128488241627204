import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isUser = new BehaviorSubject<boolean>(false);


  constructor(private afAuth: AngularFireAuth, private toastrService: ToastService ) {
    this.afAuth.authState.subscribe(userState => {
      this.isUser.next(!!userState);
    });
  }

  login(authData: {email: string, password: string}) {

    return this.afAuth.signInWithEmailAndPassword(authData.email, authData.password);
}

resetPassword(email: string) {
  return this.afAuth.sendPasswordResetEmail(email)
    .then(() => this.toastrService.success('', 'Resting link has been sent to your eamil.', {toastClass: 'opacity'}))
    .catch(error => this.toastrService.error(error.message, 'Login failed.', {toastClass: 'opacity'}));
}

logout() {
 return this.afAuth.signOut();
}

}
