import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';



const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

@Component({
  selector: 'app-intro-page',
  templateUrl: './intro-page.component.html',
  styleUrls: ['./intro-page.component.scss'],
  animations: [
    trigger('iron', [
      state('start', style({
        opacity: '1',
        transform: 'translateX(-100px)'
      })),
      state('end', style({
        opacity: '1',
        transform: 'translateX(100%)'
      })),
      transition('start => end', animate('5s')),
      transition('end => start', animate('10ms', style({opacity: '0'})))
    ])

  ]

})
export class IntroPageComponent implements OnInit, AfterViewInit {
  modalRef: MDBModalRef;
  state = 'start';
  animation: string;
  facebookLink = 'https://www.facebook.com/BlanchieLaundry';

constructor() { }


ngOnInit() {
  // this.getMobileOperatingSystem();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.state = 'end';
    }, 0);
  }
  onEnd(event) {
    this.state = 'start';
    if (event.toState === 'start') {
      setTimeout(() => {
        this.state = 'end';
      }, 0);
    }
  }

  setAnimation(event) {
    if (event.type === 'mouseover') {
      this.animation = 'animated rubberBand infinite';
    }
  }

  removeAnimation(event) {
    if (event.type === 'mouseleave') {
      this.animation = '';
    }
  }

}
