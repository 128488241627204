import { Component, OnInit,  HostListener, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MessagesService } from './messages.service';
import { UserMessage } from './message.model';
import { take } from 'rxjs/operators';
import { MDBModalRef, MDBModalService, MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { MessagePreviewComponent } from './message-preview/message-preview.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datatables',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;

  elements: UserMessage[] = [];
  headElements = ['name', 'email', 'subject', 'date'];

  searchText = '';
  previous: string;

  maxVisibleItems = 8;
  modalRef: MDBModalRef;
  messageSubscribe: Subscription;


  constructor(private cdRef: ChangeDetectorRef, private messagesService: MessagesService,
              public MessagePreviewModel: MDBModalService, public modalRefLocal: MDBModalRef) { }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }


  ngOnInit() {

   this.messageSubscribe = this.messagesService.userMessgesChanged.pipe(take(1)).subscribe(messages => {
      this.elements = messages;
      this.mdbTable.setDataSource(this.elements);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
    });
   this.messagesService.getMessages();
  }


  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  onRowclick(index) {
    this.modalRef = this.MessagePreviewModel.show(MessagePreviewComponent, {
      data: {
        name: this.elements[index].name,
        email: this.elements[index].email,
        subject: this.elements[index].subject,
        date: this.elements[index].date,
        message: this.elements[index].message,
    }
  });
  }


  emitDataSourceChange() {
    this.mdbTable.dataSourceChange().subscribe((data: any) => {
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }


  ngOnDestroy() {
    if (this.messageSubscribe) {
      this.messageSubscribe.unsubscribe();
      this.messagesService.onDestroymessages();
    }
  }
}
