import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.scss']
})
export class ConfirmLeaveComponent implements OnInit {
 private confirmLeave = new Subject<boolean>();

 constructor(private modalRef: MDBModalRef) { }

  ngOnInit() {

  }


  onLeave() {
    this.confirmLeave.next(true);
    this.modalRef.hide();
  }

  onStay() {
    this.confirmLeave.next(false);
    this.modalRef.hide();
  }

}
