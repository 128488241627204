import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService, NavbarComponent } from 'ng-uikit-pro-standard';
import { OfferComponent } from 'src/app/offer/offer.component';
import { LoginComponent } from 'src/app/login/login.component';
import { AuthService } from 'src/app/auth.service';
import { Subscription } from 'rxjs';
import { MessagesListComponent } from 'src/app/messages-list/messages-list.component';


@Component({
  selector: 'app-nvatop',
  templateUrl: './nvatop.component.html',
  styleUrls: ['./nvatop.component.scss']
})
export class NvatopComponent implements OnInit, OnDestroy {
  @ViewChild('navbar', {static: true}) navbar: NavbarComponent;
  modalRef: MDBModalRef;
  isUser: boolean;
  subscription: Subscription;
  constructor(private modalService: MDBModalService, private authService: AuthService) {

  }
  ngOnInit() {
   this.subscription = this.authService.isUser.subscribe(user => {
      this.isUser = user;
    });
  }



  login() {
    this.modalRef = this.modalService.show(LoginComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-md modal-dialog-scrollable',
      containerClass: '',
      animated: true,
  });
  }

  logOut() {
    this.authService.logout();
  }

  hideNavbar() {
    if (this.navbar.shown) {
      this.navbar.hide();
    }
  }

  openOffer() {
    this.modalRef = this.modalService.show(OfferComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg modal-dialog-scrollable',
      containerClass: '',
      animated: true,
  });
  }

  openMessages() {
    this.modalRef = this.modalService.show(MessagesListComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg modal-dialog-scrollable',
      containerClass: '',
      animated: true,
  });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
