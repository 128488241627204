
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard';
import {ReCaptcha2Component} from 'ngx-captcha';
import { MessagesService } from 'src/app/messages-list/messages.service';


@Component({
  selector: 'app-nvabtn',
  templateUrl: './nvabtn.component.html',
  styleUrls: ['./nvabtn.component.scss']
})
export class NvabtnComponent implements OnInit {
  @ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
  sending = false;
  public map: any = { lat: 33.6445039, lng: 35.6081118 };
  // @33.6445039,35.6081118,20.44z
  // 33.6445985,35.6084758
  lable = {
    color: '#066C8A',
    fontFamily: 'cursive',
    fontSize: 'large',
    fontWeight: '800',
    text: 'Blanchie Laundry',
  };
  contactForm: FormGroup;
  disabledSubmitButton = true;
  optionsSelect: Array<any>;
  dir = undefined;
  location: Observable<any>;
  isOk = false;
    @HostListener('input') oninput() {

      if (this.contactForm.valid) {
        this.disabledSubmitButton = false;
      }
    }

    constructor(fb: FormBuilder, private messageService: MessagesService, private toastrService: ToastService) {

    this.contactForm = fb.group({
      name: ['', [Validators.required, Validators.maxLength(60)]],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(60)])],
      subject: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(500)]],
      date: [Date()],
      recaptcha: ['', Validators.required]
      });
    }



    // private setCurrentLocation() {
    //   if ('geolocation' in navigator) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       console.log(position);
    //       this.dir = {
    //         origin: { lat: position.coords.latitude, lng: position.coords.longitude },
    //         destination: this.map
    //       };
    //     });
    //   }
    // }

    markerClicked() {
      // tslint:disable-next-line: max-line-length
      window.open('https://www.google.com/maps/place/Blanchie+Laundry/@33.6444076,35.6059854,17z/data=!3m1!4b1!4m5!3m4!1s0x151edf5345b048c7:0x45e1e6963c7617e7!8m2!3d33.6444076!4d35.6081794');
    }


    ngOnInit() {

    this.optionsSelect = [
      { value: 'Feedback', label: 'Feedback' },
      { value: 'Report a bug', label: 'Report a bug' },
      { value: 'Feature request', label: 'Feature request' },
      { value: 'Other stuff', label: 'Other stuff' },
      ];


    }

    get name() {
      return this.contactForm.get('name');
    }
    get email() {
      return this.contactForm.get('email');
    }
    get subject() {
      return this.contactForm.get('subject');
    }
    get message() {
      return this.contactForm.get('message');
    }



    handleSuccess() {
      this.isOk = true;
    }

    handleExpire() {
      this.isOk = false;
    }

    onSubmit() {
      this.sending = true;
      this.messageService.sendMessage(this.contactForm.value).then(() => {
        this.captchaElem.resetCaptcha();
        this.sending = false;
        this.contactForm.reset();
        this.disabledSubmitButton = true;
        this.toastrService.success('Your message has been successfully sent.',
        'Message Sent.', {toastClass: 'opacity: 1'});
      }, (error: any) => {
        this.captchaElem.resetCaptcha();
        this.sending = false;
        this.toastrService.error('Your message fail to send, please try again later.',
        'Unexpected Error.', {toastClass: 'opacity: 1'});
      });
    }

}
