import { Component, OnInit, HostListener } from '@angular/core';
import { OfferModal } from '../offer-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService, IMyOptions } from 'ng-uikit-pro-standard';
import { AuthService } from 'src/app/auth.service';
import { OfferService } from '../offer.service';
import { take} from 'rxjs/operators';
import { ConfirmLeaveComponent } from 'src/app/confirm-leave/confirm-leave.component';


@Component({
  selector: 'app-offer-edit',
  templateUrl: './offer-edit.component.html',
  styleUrls: ['./offer-edit.component.scss']
})
export class OfferEditComponent implements OnInit {

  offerForm: FormGroup;
  popUpModelRef: MDBModalRef;
  isUser: boolean;
  offerData: OfferModal;
  type: string;



  dateOptions: IMyOptions = {
    dayLabels: {su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat'},
    dayLabelsFull: {su: 'Sunday', mo: 'Monday', tu: 'Tuesday', we: 'Wednesday', th: 'Thursday', fr: 'Friday', sa:
    'Saturday'},
    monthLabels: { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10:
    'Oct', 11: 'Nov', 12: 'Dec' },
    monthLabelsFull: { 1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8:
    'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' },
    minYear: new Date().getFullYear(),
    maxYear: new Date().getFullYear() + 1,
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    showTodayBtn: true,
    showClearDateBtn: true,
  };


  constructor(private fb: FormBuilder,
              public modalRef: MDBModalRef,
              private modalService: MDBModalService,
              private authService: AuthService,
              private offerService: OfferService
              ) {}

              @HostListener('window:beforeunload', [ '$event' ])
              beforeUnloadHandler(event) {
                this.onClose(event);
              }

  get offerTitle() { return this.offerForm.get('offerTitle'); }
  get offerheading() { return this.offerForm.get('offerheading'); }
  get offertext() { return this.offerForm.get('offertext'); }
  get offerDisc() { return this.offerForm.get('offerDisc'); }
  get offervalidty() { return this.offerForm.get('offervalidty'); }
  get offerActive() { return this.offerForm.get('offerActive'); }


  ngOnInit() {

    this.initForm();
    if (this.type === 'edit' ) {
      this.offerForm.patchValue(this.offerData);
    }
  }

  initForm() {
    this.offerForm = this.fb.group({
      offerTitle: ['', [Validators.required, Validators.maxLength(100)]],
      offerheading: ['', [Validators.required, Validators.maxLength(100)]],
      offertext: ['', [Validators.maxLength(300)]],
      offerDisc: ['', [Validators.maxLength(300)]],
      offerActive: [''],
      offervalidty: [''],
    });

  }


  onSubmit() {
  if (this.type === 'edit') {
    this.offerService.updateOffer(this.offerData.id, this.offerForm.value);
  } else {
    this.offerService.addNewOffer(this.offerForm.value);
  }
  this.modalRef.hide();
  }

  onClose(event?: any) {
    if (this.offerForm.dirty && this.offerForm.touched) {
      if (event) {
       event.returnValue = false;
       return;
      }
      this.popUpModelRef = this.modalService.show(ConfirmLeaveComponent, {
       backdrop: true,
       keyboard: true,
       focus: true,
       show: false,
       ignoreBackdropClick: false,
       class: 'modal-frame modal-top',
       containerClass: '',
       animated: true,
   });
      this.popUpModelRef.content.confirmLeave.pipe(take(1)).subscribe((result: boolean) => {
     if (result) {
       this.popUpModelRef.hide();
       this.modalRef.hide();
     }
   });
      return;
    }
    this.modalRef.hide();
  }

}
