import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { OfferModal } from './offer-modal';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class OfferService {

  offerSubject = new BehaviorSubject<OfferModal[]>(null);
  offerAllSubject = new BehaviorSubject<OfferModal[]>(null);
  fbSubscription: Subscription[] = [];
  offerLoaded = false;


  constructor(private db: AngularFirestore) {}

  isOfferstLoaded() {
    if (!this.offerLoaded) {
      this.offerLoaded = true;
      this.getOffers();
    }
  }

  addNewOffer(offer: OfferModal) {
    return this.db.collection('offers').add(offer);
  }

  updateOffer(id: string, offer: OfferModal) {
    return this.db.collection('offers').doc(id).update(offer);
   }

getOffers() {
  this.fbSubscription.push(this.db.collection('offers', ref => ref.where('offerActive', '==', true)
                                  .where('offervalidty', '>=', new Date().valueOf().toString())
                                  .orderBy('offervalidty', 'desc').limit(2) )
      .snapshotChanges()
      .pipe(
          map(docData => {
           return docData.map(doc => {
                  return {
                    id: doc.payload.doc.id,
                      ...doc.payload.doc.data() as OfferModal
                  };
              });
          })
      )
      .subscribe((offerFilesData: OfferModal[]) => {
              if (!offerFilesData) { return; }
              this.offerSubject.next([...offerFilesData]);
          }));
}

getAllOffers() {
  this.fbSubscription.push(this.db.collection('offers')
      .snapshotChanges()
      .pipe(
          map(docData => {
           return docData.map(doc => {
                  return {
                    id: doc.payload.doc.id,
                      ...doc.payload.doc.data() as OfferModal
                  };
              });
          })
      )
      .subscribe((offerFilesData: OfferModal[]) => {
              if (!offerFilesData) { return; }
              this.offerAllSubject.next([...offerFilesData]);
          }));
}


killSubscriptions() {
  this.fbSubscription.forEach(sub => {
    if (sub) {
      sub.unsubscribe();
    }
  });
}


}


