import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss']
})
export class MessagePreviewComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) { }
  name: string;
  email: string;
  subject: string;
  date: Date;
  message: string;


  ngOnInit() {
  }

}
