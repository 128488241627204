import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import 'jarallax';
import { OfferService } from './offer/offer.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { filter } from 'rxjs/operators';
import { OfferModal } from './offer/offer-modal';
import { OfferPopupComponent } from './offer/offer-popup/offer-popup.component';

declare var jarallax: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'ng-uikit-pro-standard';
  contactListSubscription: any;
  public modalRef: MDBModalRef;
  offer: OfferModal;

  constructor(private offerService: OfferService, private modelService: MDBModalService ) {}

  ngAfterViewInit() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2
    });
    this.getData();
  }

  getData() {

    this.contactListSubscription =
    this.offerService.offerSubject.pipe(filter(a => a !== null)).subscribe(sub => {
     this.offer = sub[0];
     if (this.offer) {

       setTimeout(() => {
         this.openOffer();
       }, 5000);
    }

   });

    this.offerService.isOfferstLoaded();

 }
  openOffer() {
    this.modalRef = this.modelService.show(OfferPopupComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-md modal-notify modal-danger',
      containerClass: '',
      animated: true,
      data: {
        offer: this.offer,
      }
  });
  }

  ngOnDestroy() {
    if (this.contactListSubscription) {
      this.contactListSubscription.unsubscribe();
    }
    this.offerService.killSubscriptions();
  }

}
