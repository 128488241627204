
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';

import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { IntroPageComponent } from './intro-page/intro-page.component';
import { NvatopComponent } from './navigation/nvatop/nvatop.component';
import { NvabtnComponent } from './navigation/nvabtn/nvabtn.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { AgmDirectionModule } from 'agm-direction';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LoginComponent } from './login/login.component';
import { environment } from 'src/environments/environment';


import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { PhotosGaleryComponent } from './photos-galery/photos-galery.component';
import { OfferComponent } from './offer/offer.component';
import { OfferEditComponent } from './offer/offer-edit/offer-edit.component';
import { OfferPopupComponent } from './offer/offer-popup/offer-popup.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ConfirmLeaveComponent } from './confirm-leave/confirm-leave.component';
import { GaleryCarouselComponent } from './galery-carousel/galery-carousel.component';
import { MessagesListComponent } from './messages-list/messages-list.component';
import { MessagePreviewComponent } from './messages-list/message-preview/message-preview.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
  overrides: any = {
    pan: { direction: Hammer.DIRECTION_All },
    swipe: { direction: Hammer.DIRECTION_VERTICAL },
  } as any;

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_HORIZONTAL
            }]
          ]
    });
    return mc;
  }
}


@NgModule({
  declarations: [
    AppComponent,
    IntroPageComponent,
    NvatopComponent,
    NvabtnComponent,
    AboutUsComponent,
    OurServicesComponent,
    LoginComponent,
    HowWeWorkComponent,
    PhotosGaleryComponent,
    OfferComponent,
    OfferEditComponent,
    OfferPopupComponent,
    ConfirmLeaveComponent,
    GaleryCarouselComponent,
    MessagesListComponent,
    MessagePreviewComponent


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: environment.googleMap
    }),
    AgmDirectionModule,
    NgxCaptchaModule,

  ],
  entryComponents: [PhotosGaleryComponent, LoginComponent, ConfirmLeaveComponent, MessagesListComponent, MessagePreviewComponent,
                    OfferComponent, OfferPopupComponent, OfferEditComponent],
  providers: [MDBSpinningPreloader,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
