import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent implements OnInit {
  infinityClass = 'wow swing infinite';
  constructor() { }

  ngOnInit() {
  }


  mouseEnter() {
    this.infinityClass = '';
  }

  mouseLeave() {
    this.infinityClass = 'wow swing infinite';
  }

}
