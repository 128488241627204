import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserMessage } from './message.model';
import { Subscription, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  messagesSubscription: Subscription;
  userMessgesChanged = new Subject<UserMessage[]>();
  constructor(private db: AngularFirestore) { }

  sendMessage(msg: UserMessage) {
    return this.db.collection('messages').add(msg);
   }

   getMessages() {

    this.messagesSubscription = this.db
      .collection('messages')
      .snapshotChanges()
      .pipe(
        map(docData => {
        return docData.map(doc => {
          return {
            uid: doc.payload.doc.id,
            ...doc.payload.doc.data() as UserMessage
          };
        });
      })
      )
       .subscribe((messages: UserMessage[]) => {
        this.userMessgesChanged.next([...messages]);
       }, (error: Error) => console.log(error.name));
  }

  onDestroymessages() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }
}
