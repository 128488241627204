import { Component, OnInit } from '@angular/core';
import { OfferModal } from '../offer-modal';
import { Subscription } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-offer-popup',
  templateUrl: './offer-popup.component.html',
  styleUrls: ['./offer-popup.component.scss']
})
export class OfferPopupComponent implements OnInit {
  contactListSubscription: Subscription;
  offer: OfferModal;
  code: string;

  constructor( public modalRef: MDBModalRef) { }

  ngOnInit() {

    this.code = this.getUniqueNumber;
  }



 get getUniqueNumber(): string {
  return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
}


}
