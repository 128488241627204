import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsPhotosService {

  constructor() { }


  eventsPhotos = [
    {img: '../assets/galery/13.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/14.jpg',
    caption: '',
    description: '' },
    {img: '../assets/galery/10.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/8.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/9.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/16.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/5.jpg',
    caption: '',
    description: ''},

    {img: '../assets/galery/11.jpg',
    caption: '',
    description: ''},

    {img: '../assets/galery/6.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/7.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/12.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/15.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/3.jpg',
    caption: '',
    description: ''},


    {img: '../assets/galery/4.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/1.jpg',
    caption: '',
    description: ''},
    {img: '../assets/galery/2.jpg',
    caption: '',
    description: ''},

  ];
}
