import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { EventsPhotosService } from '../events-photos.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { PhotosGaleryComponent } from '../photos-galery/photos-galery.component';

@Component({
  selector: 'app-galery-carousel',
  templateUrl: './galery-carousel.component.html',
  styleUrls: ['./galery-carousel.component.scss']
})
export class GaleryCarouselComponent implements OnInit {
  modalRef: MDBModalRef;
  @ViewChild('carousel', { static: true }) public el: any;

  @HostListener('swipeleft', ['$event']) public swipePrev(event: any) {
      this.el.previousSlide();
  }

  @HostListener('swiperight', ['$event']) public swipeNext(event: any) {
      this.el.nextSlide();
  }

  constructor(private eventsPhotosService: EventsPhotosService, private modalService: MDBModalService) { }

  slides: any = [[]];

  chunk(arr: any, chunkSize: number) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  ngOnInit() {

    this.slides = this.chunk(this.eventsPhotosService.eventsPhotos, 6);

  }


  onViewClient(index) {

    this.modalRef = this.modalService.show(PhotosGaleryComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable modal-fluid modal-full-height',
      containerClass: '',
      animated: true,
      data: {
          name: index.name,
          type: index.type,
          content: {image: index.img, caption: index.caption, description: index.description}
      }
    });
  }

}
