import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent, MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { OfferModal } from './offer-modal';
import { OfferService } from './offer.service';
import { OfferEditComponent } from './offer-edit/offer-edit.component';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;
  modalRef: MDBModalRef;
  elements: OfferModal[] = [];
  selectedCategoriesValue = null;
  selectedClientSettings = null;
  subscription: Subscription;
  headElements = [
    {head: 'Offer Title', sort: 'offerTitle'},
    {head: 'Offer Validty', sort: 'offervalidty'},
    {head: 'Offer Active', sort: 'offerActive'},
   ];

   categoriesDataSelect = [];

   clientSettingsDataSelect = [];
  loaderVisible = true;
  searchText = '';
  previous: string;
  mainChecked: boolean;
  maxVisibleItems = 25;
  templateChecked = [];
  selectedItems: any[];
  disabledEditButton = true;
  disabledDeleteButton = true;
  toBedeleted = [];
  contactListSubscription: Subscription[] = [];


  constructor(private cdRef: ChangeDetectorRef,
              private modalService: MDBModalService,
              private offerService: OfferService,
              public currentModalRef: MDBModalRef) {}

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  onSelectChanged() {

    const selectedItemsLength = this.templateChecked.filter(val => val === true ).length;
    if (selectedItemsLength !== 1) {
     this.disabledEditButton = true;
    } else {
     this.disabledEditButton = false;
    }

    if (selectedItemsLength < 1) {
      this.disabledDeleteButton = true;
     } else {
      this.disabledDeleteButton = false;
     }
    this.onPaginationChanged();

  }

  onmainSelectChanged() {

    const firstItemIndex = this.mdbTablePagination.firstItemIndex;
    const lastItemIndex = this.mdbTablePagination.lastVisibleItemIndex;

    for (let i = firstItemIndex - 1 ; i < lastItemIndex ; i++) {
      if (this.mainChecked) {
        this.templateChecked[i] = true;
      } else {
        this.templateChecked[i] = false;
      }
    }
  }


  // onDeleteContacts() {
  //   this.toBedeleted = [];
  //   this.contactListSubscription.push(this.contactService.deletedSubject.subscribe(deleted => {
  //     if (deleted) {
  //       if (this.mainChecked ) {
  //         this.mainChecked = false;
  //       }
  //       this.templateChecked.forEach((temp, index) => {
  //         this.templateChecked[index] = false;
  //       });
  //     }
  //   }));
  //   this.templateChecked.forEach((item, index) => {
  //     if (item) {
  //       this.toBedeleted.push(
  //         {
  //         id: this.elements[index].contactId,
  //         data: this.elements[index].companyName + ' - ' + this.elements[index].fullName,
  //         value: ''
  //       });
  //     }
  //   });

  //   this.modalRef = this.modalService.show(ConfirmDeleteComponent, {
  //     backdrop: true,
  //     keyboard: true,
  //     focus: true,
  //     show: false,
  //     ignoreBackdropClick: false,
  //     class: 'modal-dialog-centered modal-lg modal-dialog-scrollable',
  //     containerClass: '',
  //     animated: true,
  //     data: {
  //       heading: 'Confirm Delete',
  //       content: this.toBedeleted
  //     }
  // });
  //   this.modalRef.content.confirmDelete.subscribe((result: boolean) => {
  //   if (result) {
  //     this.contactService.deleteContacts(this.toBedeleted).then((so) => {
  //       console.log(so);
  //     });
  //   }
  // });
  // }



  getData() {

     this.contactListSubscription.push(
       this.offerService.offerAllSubject.pipe(filter(a => a !== null)).subscribe(sub => {
      this.elements = sub;
      this.mdbTable.setDataSource(this.elements);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      this.loaderVisible = false;
    }));

     this.offerService.getAllOffers();

  }

  ngOnInit() {

    this.getData();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  emitDataSourceChange() {
    this.mdbTable.dataSourceChange().subscribe((data: any) => {

    });
  }


  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }


  onAddNewContact() {


    this.modalRef = this.modalService.show(OfferEditComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg modal-dialog-scrollable',
      containerClass: '',
      animated: true,
      data: {
        type: 'add',

      }
  });

  }

  onEditContact() {
    let editedItem: OfferModal;
    this.templateChecked.forEach((itm, index ) => {
      if (itm === true) {
        editedItem = this.elements[index];
      }
    });

    this.modalRef = this.modalService.show(OfferEditComponent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered modal-lg modal-dialog-scrollable',
        containerClass: '',
        animated: true,
        data: {
          type: 'edit',
          offerData: editedItem,
        }
    });
  }



  onPaginationChanged() {
    const firstItemIndex = this.mdbTablePagination.firstItemIndex;
    const lastItemIndex = this.mdbTablePagination.lastVisibleItemIndex;
    const lenghtOf = lastItemIndex - firstItemIndex + 1;

    const trueItem = [];
    for (let i = firstItemIndex - 1; i < lastItemIndex ; i++) {
      if (this.templateChecked[i] === true) {
        trueItem.push(i);
      }
    }
    if (trueItem.length === lenghtOf) {

      this.mainChecked = true;

        } else {
          this.mainChecked = false;
        }
  }

  ngOnDestroy() {
    this.contactListSubscription.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }


}
