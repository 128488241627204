import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loadingsubscription: Subscription;
  resetPasswordDisabled = false;
  resetPassMessage  = '';
  errorMessage: any;
  isLoading = false;


  constructor(private authService: AuthService,
              public modalRef: MDBModalRef) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl(null, {validators: [Validators.required]}),
      newsLetter: new FormControl(true),
   });
  }

  onSubmit() {
      this.isLoading = true;
      this.authService.login({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    }).then((val) => {
      this.closeModel();

    }).catch(err => {
      this.isLoading = false;
      this.errorMessage = err.message;
    });
      }


      onResetPassoerd() {
        this.resetPasswordDisabled = true;
        this.authService.resetPassword(this.loginForm.value.email).then(() => {
          this.resetPassMessage = 'Reseting password link has been sent to your email';
        }).catch((err: Error) => {
          this.resetPassMessage = err.message;
          this.resetPasswordDisabled = false;
        });
      }

      closeModel() {
        this.loginForm.reset();
        this.modalRef.hide();
      }

      ngOnDestroy() {
        if (this.loadingsubscription) {
          this.loadingsubscription.unsubscribe();
        }
      }


}
