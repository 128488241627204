import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { PhotosGaleryComponent } from '../photos-galery/photos-galery.component';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
  modalRef: MDBModalRef;


  constructor(private modalService: MDBModalService) { }


  text1 = `Trust your laundry to the professionals at Blanchie We use modern eco-friendly equipment and
  cleaning techniques that produces the results you are looking for.
  Your laundry is washed, dried and folded separately from our other client’s orders by trained staff.`;

  text2 = `At Blanchie, we go above and beyond to guarantee you look your best.
  Our 7-point inspection starts with advanced spot treating and stain removal.
  We even replace cracked buttons.
  Our cleaning process uses the most advanced dry cleaning techniques and detergent.
  The result? Garments that look better and last even longer.`;


  text3 = `Scared of burning your favorite attire while ironing it? We will do the work on your behalf.

  Our experts use irons according to the fabric to not just take care of the garment but to make it 100% crease free.
  Heavy ironing for regular garments while steam ironing for soft fabric is how we carry out the process of ironing
  (temperature vary depending on the fabric).`;

  text4 = `Bring household items like comforters, curtains, tablecloths or decorative pillows to
  Blanchie
  Our professional equipment has the power to remove the toughest stains and the soft touch to handle the most
  delicate fabrics. Where regular washing can cause these items to shrink or fall apart,
  our machines will preserve the color and comfort of your favorite items.`;

  ngOnInit() {
  }

}
